:root {
  --primary-dark-bg-color: #009900;
  --middle-bg-color: #acd7ef;
  --light-bg-color: #d1e9f6;
  --white-bg-color: #eff8fc;
  --dark-font-color: #3f3f3f;
  --light-font-color: #36a1dd;
  --gray-color: #3f3f3f;
  --gray-light-color: #eceff1;
  --white-color: #e3e2de;
  --milk-color: #ffffff;
  --warn-color: #d72638;
  --dark-bg-color: #1e293b;
}
