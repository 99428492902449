@import url('./commonstyles.scss');
@import url('./_variables.scss');
@import url('./theme.scss');

body {
  margin: 0;
}

html,
body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  color: var(--dark-font-color);
}

a:link {
  text-decoration: none;
}
