.container-core {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.top-box-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepper,
.tracker {
  position: relative;
  margin: 0 auto;
  max-width: 800px;
}

.stepper-content,
.tracker-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.top-stepper-content-first-step,
.top-stepper-content-second-step,
.top-stepper-content-third-step,
.top-stepper-content-fourth-step,
.top-box-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  height: 50px;
  background-color: var(--primary-dark-bg-color);
}

.top-stepper-content-header-icon {
  margin-right: 10px;
  height: 45px !important;
  width: 45px !important;
}

.top-box-content__icon {
  height: 50px;
  width: 50px;
}

.top-stepper-content__title,
.top-box-content__title {
  font-size: 20px;
  font-weight: 600;
  color: var(--milk-color);
}

/*titles*/

.main-content__title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.title-h4-dark {
  font-size: 16px;
  color: var(--primary-dark-color);
  font-weight: 600;
}

.title-h4-light {
  font-size: 16px;
  color: var(--gray-light-color);
}

.title-h5-light {
  font-size: 14px;
  color: var(--gray-light-color);
}

.title-h2-dark {
  font-size: 28px;
  font-weight: 600;
  color: var(--dark-font-color);
}

.title-h3-dark {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-font-color);
}

.mat-mdc-form-field-error {
  font-size: 10px;
}

.center-title {
  text-align: center;
}

/*btns*/

.mdc-button {
  font-size: 16px;
  font-weight: 600;
}

.accent-btn {
  color: var(--primary-dark-bg-color) !important;
  font-family: 'Montserrat', sans-serif;
}

.primary-btn {
  color: var(--gray-light-color) !important;
  font-family: 'Montserrat', sans-serif;
}

.light-icon {
  color: var(--middle-bg-color) !important;
}

/*checkbox*/
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--milk-color);
}

/*spinner*/
.spinner {
  align-self: center;
  margin-top: 40px;
}

.mat-expansion-panel-header-description {
  color: var(--dark-font-color) !important;
}

/*mat-hint*/
.mat-mdc-form-field-hint {
  font-size: 12px;
}

/*stepper*/
.mat-horizontal-stepper-content {
  transition: transform 0.6s ease-in !important;
}

.mat-step-header {
  width: 35px;
}

.mat-step-header:last-child,
.mat-step-header:first-child {
  margin-left: 12px;
}

.mat-step-header:first-child {
  margin-right: 12px;
}

/*mwssage with phone for errror request*/
.error-box-notification {
  margin-top: 15px;
}

@media screen and (max-width: 480px) {
  .top-stepper-content-third-step__number-icon {
    width: 95px !important;
  }
}

@media screen and (max-width: 415px) {
  .top-stepper-content__title,
  .top-tracker-content__title {
    font-size: 18px;
  }

  .top-stepper-content-third-step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    height: 50px;
    background-color: var(--primary-dark-bg-color);
  }
}

@media screen and (max-width: 360px) {
  .top-stepper-content-first-step__number-icon {
    width: 65px !important;
  }
}
